/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui";
import { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import SEO from "../components/SEO";
import RichText2 from "../components/RichText2";
import Helmet from "react-helmet";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { createImageUrl, getCookies } from "../utils/utils";
import { COOKIE_LOCALES } from "../components/LanguageSelector";
import { PageTypeContext } from "../context/PageTypeContext";
import { LocalizationContext } from "../context/LocalizationContext";

const FrontPageTemplate = ({ data, pageContext }) => {
  const {
    pageType,
    title,
    hero,
    metaDescription,
    metaTitle,
    contentArea,
    hidden,
    enableAbTesting,
    showNpsPopup,
    canonicalTag,
  } = data.contentfulFrontpage;

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    author,
    social,
    organization,
  } = useSiteMetadata();
  const { language, node_locale: locale } = pageContext;
  const { pageType: savedPageType, setPageType } = useContext(PageTypeContext);

  useEffect(() => {
    const cookies = getCookies();

    if (
      cookies &&
      cookies.preferredLocale &&
      cookies.preferredLocale !== COOKIE_LOCALES[language]
    ) {
      document.cookie = `preferredLocale=${COOKIE_LOCALES[language]}; domain=fennia.fi; path=/`;
      localStorage.setItem("userPreferredLanguage", language);
    }

    const userPreferredPageType = sessionStorage.getItem("preferredPageType");
    if (
      (userPreferredPageType && userPreferredPageType !== pageType) ||
      (savedPageType && savedPageType !== pageType)
    ) {
      sessionStorage.setItem("preferredPageType", pageType);
      setPageType(pageType);
    }
  });

  const { translate } = useContext(LocalizationContext);

  let metaImage =
    hero && hero.image && hero.image.gatsbyImageData
      ? createImageUrl(hero.image.gatsbyImageData)
      : siteUrl + defaultImage;

  let metadataTitle = metaTitle || title;

  return (
    <Layout
      pageContext={pageContext}
      pageType={pageType}
      enableAbTesting={enableAbTesting}
      showNpsPopup={showNpsPopup}
      showLogOutSurveys
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Website",
            url: siteUrl,
            name: defaultTitle,
            headline: defaultDescription,
            mainEntityOfPage: siteUrl,
            image: {
              "@type": "ImageObject",
              url: `${metaImage}`,
            },
            publisher: {
              "@type": "Organization",
              name: author,
              address: {
                "@type": "PostalAddress",
                streetAddress: organization.address,
                postalCode: organization.postalCode,
                addressLocality: organization.addressLocality,
              },
              logo: {
                "@type": "ImageObject",
                url: `${siteUrl}${defaultImage}`,
              },
              contactPoint: [
                {
                  "@type": "ContactPoint",
                  telephone: "010 5031",
                  contactType: "Puhelin (vaihde)",
                  availableLanguage: ["Finnish", "Swedish", "English"],
                },
                {
                  "@type": "ContactPoint",
                  telephone: "010 503 8808",
                  contactType: "Asiakaspalvelu, kotitaloudet",
                  availableLanguage: ["Finnish", "Swedish", "English"],
                },
                {
                  "@type": "ContactPoint",
                  telephone: "010 503 8818",
                  contactType: "Asiakaspalvelu, yritykset",
                  availableLanguage: ["Finnish", "Swedish", "English"],
                },
              ],
            },
            sameAs: [
              social.twitter,
              social.facebook,
              social.linkedIn,
              social.youtube,
            ],
          })}
        </script>
        <meta
          name="facebook-domain-verification"
          content="zs2blx1pf4di8hlozk4019kwvfqk4n"
        />
        <meta
          name="google-site-verification"
          content="BaebfODp9uYB3mI-E3f3nxwn1_ZcP32GkgHHlCLlQeY"
        />
      </Helmet>
      <SEO
        lang={pageContext.language}
        altTitle={translate("frontPageTitle", null, language)}
        title={title}
        description={metaDescription ? metaDescription.internal.content : ""}
        image={metaImage}
        paths={pageContext.paths}
        hidden={hidden}
        metadataTitle={metadataTitle}
        canonicalTag={canonicalTag}
      />
      <Container id="content">
        <Box sx={{ mx: [-3, -4, 0] }}>
          <Hero {...hero} />
        </Box>

        {contentArea && contentArea.raw && (
          <RichText2
            content={contentArea}
            locale={locale}
            language={language}
          />
        )}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    contentfulFrontpage(id: { eq: $id }) {
      id
      contentful_id
      __typename
      pageType
      title
      navigationTitle
      slug
      hero {
        title
        type
        description
        node_locale
        image {
          gatsbyImageData(quality: 85, width: 1080)
        }
        linkText
        target
        analyticsAction
        analyticsId
        id
        contentful_id
        __typename
        link {
          ... on ContentfulFrontpage {
            slug
          }
          ... on ContentfulPage {
            slug
          }
          ... on ContentfulProductPage {
            slug
          }
          ... on ContentfulThemePage {
            slug
          }
        }
      }
      canonicalTag
      metaKeywords
      metaDescription {
        internal {
          content
        }
      }
      metaTitle
      hidden
      contentArea {
        raw
        references {
          ... on Node {
            ...fragmentContentfulFrontpage
          }
          ... on Node {
            ...fragmentContentfulThemePage
          }
          ... on Node {
            ...fragmentContentfulProductPage
          }
          ... on Node {
            ...fragmentContentfulPage
          }
          ... on Node {
            ...fragmentContentfulContentHubThemePage
          }
          ... on Node {
            ...fragmentContentfulContentHubPage
          }
          ... on Node {
            ...fragmentContentfulContentHubContentTypePage
          }
          ... on Node {
            ...fragmentContentfulContentHubTopicPage
          }
          ... on Node {
            ...fragmentContentfulContentfulInsuranceTermsPage
          }
          ...fragmentContentfulCallToAction
          ...fragmentContentfulComponent
          ...fragmentContentfulGrid
          ...fragmentContentfulHero
          ...fragmentContentfulLiftUpLinks
          ... on Node {
            ...fragmentContentfulDynamicSection
          }
        }
      }
      enableAbTesting
      showNpsPopup
    }
  }
`;
export default FrontPageTemplate;
